import React from 'react';
import { Link } from 'gatsby';
import { Typography, Box, Divider, Link as MuiLink } from '@material-ui/core';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Prose from '../components/Prose';

const Page = () => (
  <Layout>
    <SEO title="Copyrights" />
    <Box textAlign="center" mb={6}>
      <Typography variant="h1">Copyrights</Typography>
    </Box>

    <Box mb={8}>
      <Divider />
    </Box>

    <Prose>
      Some content used by permission from FreeBibleImages.com, with attribution
      to College Press Publishing, Good News Productions International, Moody
      Publishers, and Sweet Publishing. Please{' '}
      <MuiLink component={Link} to="/contact">
        contact us
      </MuiLink>{' '}
      for any questions about other content on our website.
    </Prose>
  </Layout>
);

export default Page;
